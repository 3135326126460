.cart-items {
  background: #f6f9fc;
  min-height: 100vh;
}

/* .cart-items .cart-details {
  width: 70%;
} */

.cart-items .cart-total {
  /* width: 50% !important; */
  height: fit-content;
  line-height: 50px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
}

.product-cart {
  background: #fff;
  padding: 2.5rem 3rem;
  line-height: 50px;
  border-radius: 10px;
  box-shadow: rgb(3 0 71/ 9%) 0 1px 3px;
  margin-top: 10px;
}

.cart-details {
  flex: 1;
}

.cart-total .checkout {
  height: fit-content;
  width: fit-content;
  background-color: antiquewhite;
  color: black;
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
}

.cart-total .checkout:hover {
  background: gray;
  color: #fff;
}

.cart-list {
  background: white;
  margin-top: 30px;
}

.cart-items .img img {
  width: 150px;
  height: 150px;
}

.cart-items .cart-details h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

.cart-items .cart-details h4 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 50px;
  color: grey;
}

.cart-details span {
  color: #e94560;
  font-weight: 500;
}

.removeCart {
  background: none;
  font-size: 25px;
  text-align: right;
  margin-right: 10px;
}

.cartControl {
  margin-top: 50px;
}

.cartControl button {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  font-size: 20px;
}

.inCart {
  background-color: none;
}

.delCart {
  background: #f6f9fc;
}

.cart-total h3 {
  font-size: 20px;
  font-weight: 500;
  color: #e94560;
}

.cart-total h2 {
  border-bottom: 1px solid rgb(3 0 71 / 9%);
  font-weight: 700;
}

.cart-total h4 {
  font-size: 15px;
  font-weight: 400;
}

.d-block {
  display: block;
  width: 100%;
  /* background-color: #e94560; */
}

.input-style {
  border: solid gray 1px;
  width: 100%;
  padding: 10px;
}

.btn-add {
  padding: 6px;
  background-color: rgb(69, 113, 3);
  font-weight: 400;
  font-size: medium;
  border-radius: 4px;
  color: #fff;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  width: 100%;
  padding: 6px;
  background: darkslategray;
  color: floralwhite;
  outline: none;
  border-radius: 4px;
}

.mb-5 {
  margin-bottom: 6px;
}

.-mb-5 {
  margin-bottom: -15px;
}

.c-white {
  color: #fff;
}

.d-justify-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
