.allProductsSection{
    min-height: 100vh;
}

.allproductsheading{
    text-align: center;
    padding-top: 30px;
    font-size: 2rem;
}

