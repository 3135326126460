footer {
  background-color: #0f3460;
  padding: 50px 70px;
  color: #fff;
}

footer .img {
  background-color: #0c2a4d;
  padding: 15px 20px;
  border-radius: 5px;
}

footer .img i {
  font-size: 24px;
  margin: 5px;
}

footer p {
  font-size: 14px;
  font-weight: 300;
  opacity: 0.5;
  margin-bottom: 20px;
}

footer h2 {
  margin-bottom: 20px;
}

footer li {
  margin-bottom: 10px;
  opacity: 0.5;
  cursor: pointer;
}

.icon-footer {
  cursor: pointer;
}
