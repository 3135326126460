.slider-container {
  position: relative;
  display: flex;
  gap: 5rem;
}

.slider h1 {
  font-size: 2.7rem;
}

.slider p {
  margin: 10px 0;
  font-size: 1.2rem;
}

.slick-track {
  display: flex;
  align-items: center;
}

.slider ul {
  position: absolute;
  bottom: -30%;
}

.slider ul li button {
  background: transparent;
  color: transparent;
}

.slider ul li button::before {
  font-size: 15px;
  color: #0f3460;
  position: absolute;
  top: 2px;
  left: 0;
  margin: auto;
}

.slider .box {
  display: flex !important;
  gap: 5rem;
}

.slider .box .left {
  width: 50%;
  margin: 10px;
}

.slider .box .right {
  width: 50%;
  margin: 10px;
}

.slider .box img {
  max-width: 100%;
  height: 100%;
}

.img img {
  width: 100%;
  height: auto;
}

/* Product Related CSS */

.product {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71/ 9%) 0 1px 3px;
  margin: 10px;
}

.product-top {
  background: #fff;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71/ 9%) 0 1px 3px;
}

.product-shop {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71/ 9%) 0 1px 3px;
}

.product-content {
  width: 100%;
  display: grid;
  /* gap: 1rem; */
  grid-template-columns: repeat(3, 1fr);
}

.product h3 {
  font-weight: 500;
  font-size: 20px;
}

.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}

.product .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}

.product button {
  background: none;
  color: #e94560;
  font-size: 20px;
  border: 1px solid rgb(3 0 71/9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
  transition: 0.3s;
}

/* .product button:hover {
  cursor: pointer;
  background: "blue";
  color: #fff;
} */

.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.3s;
}

.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 50px;
}

.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}

.product:hover .product-like {
  opacity: 1;
  cursor: pointer;
}

.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 55px;
  background: #797a7c;
  border-radius: 50%;
  transition: 0.5s;
  z-index: 5;
}

.control-btn .prev {
  left: -20px;
}

.control-btn .next {
  right: -20px;
}

.control-btn .next i,
.control-btn .prev i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}

.control-btn .next:hover,
.control-btn .prev:hover {
  background: #07e162;
  color: #fff;
  cursor: pointer;
}

@media only screen and (max-width: 1440px) {
  .slider-container {
    gap: 2rem;
  }

  .slider .box {
    gap: 1rem;
  }

  .product-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1024px) {
  .slider-container {
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .slider h1 {
    font-size: 1.3rem;
  }

  .slider p {
    /* margin: 16px 0; */
    font-size: 0.8rem;
  }

  .slider ul {
    position: absolute;
    bottom: -5%;
  }

  .slider ul li button {
    background: transparent;
    color: transparent;
  }

  .contentWidth {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 768px) {
  .control-btn .next,
  .control-btn .prev {
    position: absolute;
    top: 40%;
    width: 30px;
    height: 30px;
    line-height: 55px;
    background: #0f3460;
    border-radius: 50%;
    transition: 0.5s;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .control-btn .next i,
  .control-btn .prev i {
    font-size: 10px;
    transition: 0.5s;
    color: #fff;
  }

  .control-btn .prev {
    left: -10px;
  }

  .control-btn .next {
    right: -10px;
  }

  .product-content {
    grid-template-columns: repeat(1, 1fr);
  }
}
