.topCat {
  position: relative;
}

.topCat .box {
  margin: 0 20px;
}

.topCat img {
  width: 100%;
  height: 100%;
}

.topCat .nametop {
  position: relative;
  width: 100%;
}

.topCat .nametop span {
  background-color: #0f3460;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 50px;
  margin: 5px;
  position: absolute;
  top: 0;
}

.topCat .nametop .tLeft {
  left: 0;
}

.topCat .nametop .tRight {
  background-color: aliceblue;
  color: #000;
  position: absolute;
  right: 0;
}
