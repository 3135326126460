.bg-blue-cat {
  background-color: blue;
}

.my-d-flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  gap: 20px;
  /* flex-wrap: wrap; */
}

.bg-cat1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/clothing.webp");
  background-size: cover;
  background-position: center;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  border-radius: 12px;
}

.bg-cat2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/herbal.webp");
  background-size: cover;
  background-position: bottom;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/health.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/grocery.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat5 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/wellness.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat6 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/personal-care.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat7 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/leather-goods.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat8 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/home-care-cleaning.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat9 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/home-appliances.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat10 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/fitness-equipments.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat11 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/utility-items.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.bg-cat12 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://voyger.online/voyger/assets/cat-img/dailylife.webp");
  background-size: cover;
  background-position: cover;
  height: 15vh;
  width: 100%;
  background-attachment: scroll;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.my-m-x {
  margin-left: 3%;
  margin-right: 3%;
}

.my-m-y {
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

.m-y-my {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-text-white {
  color: #fff;
}

.my-center {
  display: flex;
  height: 15vh;
  font-size: larger;
  justify-content: center;
  align-items: center;
}

.slick-slide > div {
  padding: 0 10px; /* Ensures padding inside each slide */
}

.slick-list {
  margin: 0 -10px; /* Adjusts the slider track to prevent overflow */
}
