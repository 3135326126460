.cp-container {
  /* padding: 20px; */
  padding-bottom: 40px;
  background-color: #f0f8ff; /* Light blue background */
}

.cp-text-center {
  text-align: center;
  color: #007bff; /* Blue text */
  margin-bottom: 20px;
}

.cp-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cp-card {
  background-color: white;
  border: 2px solid #007bff; /* Blue border */
  border-radius: 8px;
  padding: 16px;
  margin: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  color: #333;
  transition: transform 0.3s ease;
  position: relative;
}

.cp-card:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.cp-card h3 {
  /* margin: 8px 0; */
  font-size: 15px;
  color: #333;
}

.cp-card p {
  /* margin: 8px 0; */
  font-size: 12px;
  color: #333;
}

.cp-card h3:first-child {
  color: #28a745; /* Green text for customer name */
}

.cp-btn {
  background: #28a745;
  padding: 10px 18px;
  border-radius: 9px;
  width: 90%;
  position: absolute;
  bottom: 6px;
  color: white;
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
}

.cp-btn:hover {
  background-color: #218838;
}

.cp-m-b {
  margin-bottom: 40px !important;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .cp-card {
    width: 100%; /* Full width on smaller screens */
  }
}
