.wrapper {
  text-align: center;
}

.wrapper .img {
  margin: auto;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin-bottom: 20px;
}

.wrapper .img i {
  font-size: 25px;
  padding-top: 23px;
}

.wrapper .product {
  padding: 30px;
}

.wrapper .product h3 {
  font-weight: 500;
}

.wrapper .product p {
  font-size: 15px;
  margin-top: 20px;
  color: grey;
}

/* @media only screen and (max-width: 820px) {
  .grid2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}

@media only screen and (max-width: 690px) {
  .grid2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
} */
